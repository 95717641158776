.yf-default-form {
  height: 35px;
  margin: 0 5px;
}

.form-section-header {
  background-color: #eee;
  padding: 1px 8px;
  margin: 2px 15px 10px 0px;
  border-radius: 4px;
  color: #444;
  font-weight: bold;
  font-size: 10pt;
}

.fi-normal {
  flex: 3;
  padding-left: 0px;
  padding-right: 14px;
  padding-bottom: 4px;
}

.fg {
  display: flex;
  flex-direction: row;
  align-items: start;
}

// .fg-filters {
//   display: flex;
//   flex-direction: row;
//   align-items:center;
//   nz-form-item{
//     padding-right: 10px;
//   }
// }

.fi {
  padding-left: 0px;
  padding-right: 14px;
  padding-bottom: 2px;
}

.fg-wrap {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: start;
}

.fi-xsmall {
  flex: 1;
  padding-left: 0px;
  padding-right: 14px;
  padding-bottom: 4px;
}

.fi-small {
  flex: 2;
  padding-left: 0px;
  padding-right: 14px;
  padding-bottom: 4px;
}

.fi-normal {
  flex: 3;
  padding-left: 0px;
  padding-right: 14px;
  padding-bottom: 4px;
}

.fi-large {
  flex: 4;
  padding-left: 0px;
  padding-right: 14px;
  padding-bottom: 4px;
}

.fi-xlarge {
  flex: 5;
  padding-left: 0px;
  padding-right: 14px;
  padding-bottom: 4px;
}

.fi-hide {
  border: 2px solid orange;
}

.fi-full {
  flex: 6;
  padding-left: 0px;
  padding-right: 14px;
  padding-bottom: 4px;
}

.fi-full-textarea {
  flex: 6;
  padding-left: 0px;
  padding-right: 14px;
  padding-bottom: 4px;
}

.fi-full-textarea textarea {
  min-height: 300px;
  background-color: #333;
  color: lightgreen;
  font-family: 'Courier New', Courier, monospace;
  font-size: small;
}

.fi-header * {
  border: 0px;
  font-weight: bold;
}

.form-section-header {
  background-color: #eee;
  padding: 1px 8px;
  margin: 2px 15px 10px 0px;
  border-radius: 4px;
  color: #444;
  font-weight: bold;
  font-size: 10pt;
}


.icon-24 {
  font-size: 24px;
}

.debug {
  border: 1px solid blue;
  background-color: rgb(0, 115, 255);
}

.fi-column .ant-form-item {
  padding-left: 0px;
  padding-right: 14px;
  padding-bottom: 2px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include breakpoint(xs, max) {
    padding-right: 35px;
    padding-left: 0px;
    padding-bottom: 0px;
    margin-bottom: 8px;
  }
}

// .fi-column .ant-picker{
//   margin-top: 32px;

//   @include breakpoint(xs, max) {
//     margin: 8px 8px 12px 0px
//   }
// }

.ant-picker{
  margin-top: 6px;

  @include breakpoint(xs, max) {
    margin: 8px 0px 8px 5px
  }
}

.fi-column .ant-select-single {
  width: 150px;
}