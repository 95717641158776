@import '../abstracts/mixins.scss';
@import '../abstracts/variables.scss';

.yf-refresh-button-right {
  float: right;
  width: 32px;
  height: 32px;
  margin-left: 5px;
}
.yf-add-button-right{
  float: right;

}

.yf-addnew-button-right {
  float: right;
  width: 32px;
  height: 32px;
  margin-left: 5px;
}

.yf-edit-button-right {
  float: right;
  width: 32px;
  height: 32px;
  margin-left: 5px;
}

.search-container button {
  align-self: center;
}

.search-container .filter-active {
  align-self: center;
  color: #fff;
  border-color: #1890ff;
  background: #1890ff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.search-container .filter-non-active {
  align-self: center;
}
