  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-track {
    background: rgb(173, 173, 173);
  }

  ::-webkit-scrollbar-thumb {
    background: var(--yf-secondary);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .yf-pointer {
    cursor: pointer;
  }

  .yf-scroll-y {
    overflow-y: auto;
  }

  .ant-select-selection-item {
    text-align: left !important;
  }

  .container-block-log {
    width: 100%;
    margin: 20px;
  }

  @for $i from 2 through 96 {
    .fs-#{$i} {
      font-size: #{$i}px;
    }
  }

  @for $i from 100 through 900 {
    .fw-#{$i} {
      font-weight: #{$i};
    }
  }

  .mobile-card {
    width: 90vw;
    margin-bottom: 16px;
    cursor: pointer;
    border-radius: 10px;
    background-color: rgb(254, 254, 254);
  }

  .cards-container {
    width: 100%;
    height: calc(100% - 90px);
  }

  .mobile-footer {
    height: 40px;
    background-color: white;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
  }

  .search-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 10px;

    @include breakpoint(xs, max) {
      padding-top: 12px;
      // padding-left: 12px;
    }

    .search-bar {
      @include breakpoint(xs, max) {
        //padding: 0 5px 5px;
        width: 65%;
        align-items: flex-start;
      }
    }

    .search-buttons-container {
      display: inline;
      display: flex;
      align-items: center;

      @include breakpoint(xs, max) {
        margin-right: 25px;
      }
    }

    .search-buttons-container-plus {
      display: inline;
      display: flex;
      align-items: center;

      @include breakpoint(xs, max) {
        margin-right: 20px;
        width: 68px;
        justify-content: space-between;
      }
    }

  }

  .page-cont {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .btn-add {
    align-self: center;
    justify-content: flex-end;

    @include breakpoint(xs, min) {
      margin-right: 12px;
    }
  }

  .thumbnail {
    height: 60px;
    width: 120px;
    object-fit: cover;
    object-position: left;
  }

  .thumbnail-lg {
    width: 100%;
    object-fit: cover;
    object-position: left;
  }


  .w-full {
    width: 100%;
  }

  .search-bar-filters {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    gap: 2%;

    @include breakpoint(xs, max) {
      justify-content: flex-start;
      margin-left: 25px;
      flex-wrap: nowrap;
      width: 70%
    }
  }

  .search-bar-filters-plus {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    gap: 2%;

    @include breakpoint(xs, max) {
      justify-content: flex-start;
      margin-left: 20px;
      flex-wrap: nowrap;
      width: 60%
    }
  }

  .filters-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    @include breakpoint(xs, max) {
      margin-left: 32px;
      margin-bottom: 8px;
    }
  }

  .filters-form-session {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    @include breakpoint(xs, max) {
      margin-left: 32px;
      margin-bottom: 8px;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .sorting-or-form-flex-start {
    display: flex;
    justify-content: flex-start;

    @include breakpoint(xs, max) {
      margin-left: 32px;
      margin-bottom: 8px;
    }
  }

  .reset-filter {
    width: 100px;
    margin-top: 6px;

    @include breakpoint(xs, max) {
      width: 105px;
      margin-top: 31px;
      margin-bottom: 0px;
    }
  }

  .reset-filter-more-select {
    width: 100px;
    margin-top: 6px;
    margin-left: 16px;

    @include breakpoint(xs, max) {
      width: 105px;
      margin-top: 5px;
      margin-bottom: 15px;
      margin-left: 5px
    }
  }

  .ant-divider-horizontal.ant-divider-with-text {
    margin: 8px 0px;
  }