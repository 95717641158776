.yf-flex{
  display: flex;
}
.yf-flex-center{
  justify-content: center;
  align-items: center;
}
.yf-flex-align-center{
  align-items: center;
}
.yf-flex-align-start{
  align-items: start;
}
.yf-fit-content {
  width: fit-content;
}
.yf-flex-space-between{
  justify-content: space-between;
}
.yf-wrap{
  flex-wrap: wrap;
}

.yf-flex-right {
  justify-content: end;
}
.yf-flex-col{
  flex-direction: column;
}
.yf-flex-row {
  flex-direction: row;
}

@for $i from 1 through 24 {
  .yf-gap-#{$i} {
    gap: #{$i}px;
  }
}


.yf-jc-end{
  justify-content: flex-end;
}

.yf-flex-grow {
  flex-direction: row;
}