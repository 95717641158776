@import "abstracts/variables.scss";
@import "abstracts/mixins.scss";
@import "base/reset.scss";
@import "base/typography.scss";
@import "layout/forms.scss";
@import "layout/sidebar.scss";
@import "components/buttons.scss";
@import "layout/utilities.scss";
@import "components/text.scss";
@import "layout/grid.scss";
@import "layout/margins.scss";
@import '~quill/dist/quill.snow.css';
.yf-inline-block{
  display: inline-block;
}
.ant-table-row{
  height: 10px !important;
}
