/* You can add global styles to this file, and also import other style files */
@import "./theme/main.scss";
@import '~maplibre-gl/dist/maplibre-gl.css';

.form-size.forml.ant-form-item-required {
  width: 100% !important;

  display: inline-block !important;

  text-align: right !important;
}

.form-size.ant-form-item-label {
  width: 90px !important;
}
// .ant-form-item-control-input {
//   text-align: right !important;
// }
body{
  overflow-y:hidden;
}
.ant-page-header{
  padding: 12px 20px;
}
.ant-table-row{
  height: 10px !important;
}

.updatedAt {
  color: #666;
}
